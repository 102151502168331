import { createIcon } from '@chakra-ui/react';

const defaults = {
    viewBox: '0 0 48 48',
    defaultProps: { boxSize: 6, color: 'mode.text1' },
};

export const ColorModeDark = createIcon({
    displayName: 'ColorModeDark',
    d: 'M12 3a9 9 0 109 9c0-.46-.04-.92-.1-1.36a5.389 5.389 0 01-4.4 2.26 5.403 5.403 0 01-3.14-9.8c-.44-.06-.9-.1-1.36-.1z',
    viewBox: '0 0 24 24',
});

export const ColorModeLight = createIcon({
    displayName: 'ColorModeLight',
    d: 'M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zM2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1zm18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1zm0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zM5.99 4.58a.996.996 0 00-1.41 0 .996.996 0 000 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41L5.99 4.58zm12.37 12.37a.996.996 0 00-1.41 0 .996.996 0 000 1.41l1.06 1.06c.39.39 1.03.39 1.41 0a.996.996 0 000-1.41l-1.06-1.06zm1.06-10.96a.996.996 0 000-1.41.996.996 0 00-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06zM7.05 18.36a.996.996 0 000-1.41.996.996 0 00-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06z',
    viewBox: '0 0 24 24',
});

export const CommentIcon = createIcon({
    ...defaults,
    displayName: 'CommentIcon',
    d: 'M47.5 46.1l-2.8-11c1.8-3.3 2.8-7.1 2.8-11.1C47.5 11 37 .5 24 .5S.5 11 .5 24 11 47.5 24 47.5c4 0 7.8-1 11.1-2.8l11 2.8c.8.2 1.6-.6 1.4-1.4zm-3-22.1c0 4-1 7-2.6 10-.2.4-.3.9-.2 1.4l2.1 8.4-8.3-2.1c-.5-.1-1-.1-1.4.2-1.8 1-5.2 2.6-10 2.6-11.4 0-20.6-9.2-20.6-20.5S12.7 3.5 24 3.5 44.5 12.7 44.5 24z',
});

export const DirectMessagesIcon = createIcon({
    ...defaults,
    displayName: 'DirectMessagesIcon',
    d: 'M47.8 3.8c-.3-.5-.8-.8-1.3-.8h-45C.9 3.1.3 3.5.1 4S0 5.2.4 5.7l15.9 15.6 5.5 22.6c.1.6.6 1 1.2 1.1h.2c.5 0 1-.3 1.3-.7l23.2-39c.4-.4.4-1 .1-1.5zM5.2 6.1h35.5L18 18.7 5.2 6.1zm18.7 33.6l-4.4-18.4L42.4 8.6 23.9 39.7z',
});

export const EllipsisIcon = createIcon({
    ...defaults,
    displayName: 'EllipsisIcon',
    viewBox: '0 0 24 24',
    path: (
        <svg fill="currentColor">
            <circle cx="12" cy="12" r="1.5" />
            <circle cx="6.5" cy="12" r="1.5" />
            <circle cx="17.5" cy="12" r="1.5" />
        </svg>
    ),
});

export const EmojiIcon = createIcon({
    ...defaults,
    displayName: 'EllipsisIcon',
    path: (
        <svg fill="currentColor">
            <path d="M24 48C10.8 48 0 37.2 0 24S10.8 0 24 0s24 10.8 24 24-10.8 24-24 24zm0-45C12.4 3 3 12.4 3 24s9.4 21 21 21 21-9.4 21-21S35.6 3 24 3z"></path>
            <path d="M34.9 24c0-1.4-1.1-2.5-2.5-2.5s-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5zm-21.8 0c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5zM24 37.3c-5.2 0-8-3.5-8.2-3.7-.5-.6-.4-1.6.2-2.1.6-.5 1.6-.4 2.1.2.1.1 2.1 2.5 5.8 2.5 3.7 0 5.8-2.5 5.8-2.5.5-.6 1.5-.7 2.1-.2.6.5.7 1.5.2 2.1 0 .2-2.8 3.7-8 3.7z"></path>
        </svg>
    ),
});

export const FindPeopleIcon = createIcon({
    ...defaults,
    displayName: 'FindPeopleIcon',
    d: 'M24 0C10.8 0 0 10.8 0 24s10.8 24 24 24 24-10.8 24-24S37.2 0 24 0zm0 45C12.4 45 3 35.6 3 24S12.4 3 24 3s21 9.4 21 21-9.4 21-21 21zm10.2-33.2l-14.8 7c-.3.1-.6.4-.7.7l-7 14.8c-.3.6-.2 1.3.3 1.7.3.3.7.4 1.1.4.2 0 .4 0 .6-.1l14.8-7c.3-.1.6-.4.7-.7l7-14.8c.3-.6.2-1.3-.3-1.7-.4-.5-1.1-.6-1.7-.3zm-7.4 15l-5.5-5.5 10.5-5-5 10.5z',
});

export const HomeIcon = createIcon({
    ...defaults,
    displayName: 'HomeIcon',
    d: 'M45.5 48H30.1c-.8 0-1.5-.7-1.5-1.5V34.2c0-2.6-2.1-4.6-4.6-4.6s-4.6 2.1-4.6 4.6v12.3c0 .8-.7 1.5-1.5 1.5H2.5c-.8 0-1.5-.7-1.5-1.5V23c0-.4.2-.8.4-1.1L22.9.4c.6-.6 1.6-.6 2.1 0l21.5 21.5c.3.3.4.7.4 1.1v23.5c.1.8-.6 1.5-1.4 1.5z',
});

export const HomeFilledIcon = createIcon({
    ...defaults,
    displayName: 'HomeFilledIcon',
    viewBox: '0 0 100 100',
    d: 'm50 22.918-25.191 18.895v35.266h17.004v-21.41h16.375v21.41h17.004v-35.266z',
});

export const HomeOutlineIcon = createIcon({
    ...defaults,
    displayName: 'HomeOutlineIcon',
    viewBox: '0 0 100 100',
    d: 'm49.254 21.895-25.191 18.895c-0.32031 0.23438-0.51562 0.62891-0.51172 1.0234v35.27c0 0.66016 0.60156 1.2578 1.2578 1.2578h17.004c0.66016 0 1.2578-0.60156 1.2578-1.2578v-15.117h13.855v15.117c0 0.66016 0.60156 1.2578 1.2578 1.2578h17.004c0.66016 0 1.2578-0.60156 1.2578-1.2578v-35.27c0-0.39453-0.19141-0.78906-0.51172-1.0234l-25.191-18.895c-0.57813-0.33984-0.99609-0.28516-1.4961 0zm0.74609 2.5977 23.93 17.949v33.379h-14.484v-15.117c0-0.66016-0.60156-1.2578-1.2578-1.2578h-16.375c-0.66016 0-1.2578 0.60156-1.2578 1.2578v15.117h-14.484v-33.379z',
});

export const HeartFilledIcon = createIcon({
    ...defaults,
    defaultProps: {
        ...defaults.defaultProps,
        color: 'tomato',
    },
    displayName: 'HeartFilledIcon',
    d: 'M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z',
});

export const HeartOutlineIcon = createIcon({
    ...defaults,
    displayName: 'HeartOutlineIcon',
    d: 'M34.6 6.1c5.7 0 10.4 5.2 10.4 11.5 0 6.8-5.9 11-11.5 16S25 41.3 24 41.9c-1.1-.7-4.7-4-9.5-8.3-5.7-5-11.5-9.2-11.5-16C3 11.3 7.7 6.1 13.4 6.1c4.2 0 6.5 2 8.1 4.3 1.9 2.6 2.2 3.9 2.5 3.9.3 0 .6-1.3 2.5-3.9 1.6-2.3 3.9-4.3 8.1-4.3m0-3c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5.6 0 1.1-.2 1.6-.5 1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z',
});

export const LoveFilledIcon = createIcon({
    ...defaults,
    displayName: 'LoveFilledIcon',
    viewBox: '0 0 100 100',
    d: 'm81.172 22.508c-3.8789-4.0547-9.1328-6.5156-14.734-6.9023-5.5977-0.38672-11.141 1.332-15.539 4.8203-0.52344 0.42578-1.2734 0.42578-1.7969 0-4.3984-3.4883-9.9375-5.207-15.539-4.8203s-10.855 2.8477-14.734 6.9023c-4.082 4.4102-6.2852 10.242-6.1406 16.25 0.14844 6.0078 2.6328 11.723 6.9297 15.93l27.172 27.164c0.85156 0.85156 2.0078 1.332 3.2109 1.332s2.3594-0.48047 3.2109-1.332l27.172-27.164c4.2969-4.207 6.7812-9.9219 6.9297-15.93 0.14453-6.0078-2.0586-11.84-6.1406-16.25z',
});

export const LoveOutlineIcon = createIcon({
    ...defaults,
    displayName: 'LoveOutlineIcon',
    viewBox: '0 0 100 100',
    d: 'm50.898 20.426c-0.52344 0.42578-1.2734 0.42578-1.7969 0-4.3984-3.4883-9.9375-5.207-15.539-4.8203s-10.855 2.8477-14.734 6.9023c-4.082 4.4102-6.2852 10.242-6.1406 16.25 0.14844 6.0078 2.6328 11.723 6.9297 15.93l27.172 27.164c0.85156 0.85156 2.0078 1.332 3.2109 1.332s2.3594-0.48047 3.2109-1.332l27.172-27.164c4.293-4.207 6.7773-9.918 6.9258-15.926 0.14453-6.0078-2.0547-11.836-6.1367-16.246-3.8789-4.0586-9.1328-6.5195-14.73-6.9062-5.6016-0.38672-11.145 1.332-15.543 4.8164zm27.273 32.047-27.172 27.168c-0.55859 0.53906-1.4414 0.53906-2.0039 0l-27.168-27.168c-3.7188-3.625-5.875-8.5547-6.0156-13.746-0.14062-5.1914 1.7461-10.23 5.2656-14.051 3.3438-3.4961 7.8711-5.6172 12.699-5.9492 4.8242-0.33203 9.6016 1.1484 13.395 4.1523 1.6602 1.3164 4.0039 1.3164 5.6641 0 3.793-3.0039 8.5664-4.4844 13.395-4.1523 4.8281 0.33203 9.3555 2.4531 12.699 5.9492 3.5156 3.8203 5.4023 8.8633 5.2617 14.051-0.14062 5.1914-2.3008 10.121-6.0195 13.746z',
});

export const NewPostIcon = createIcon({
    ...defaults,
    displayName: 'NewPostIcon',
    viewBox: '0 0 100 100',
    path: (
        <svg fill="currentColor">
            <g>
                <path d="m72.344 15.82h-44.688c-6.5234 0-11.836 5.3125-11.836 11.836v44.707c0 6.5234 5.3125 11.836 11.836 11.836h44.707c6.5234 0 11.836-5.3125 11.836-11.836v-44.707c-0.019531-6.5234-5.332-11.836-11.855-11.836zm8.8477 56.523c0 4.8828-3.9648 8.8477-8.8477 8.8477h-44.688c-4.8828 0-8.8477-3.9648-8.8477-8.8477v-44.688c0-4.8828 3.9648-8.8477 8.8477-8.8477h44.707c4.8828 0 8.8477 3.9648 8.8477 8.8477v44.688z" />
                <path d="m64.141 48.496h-12.656v-12.656c0-0.82031-0.66406-1.5039-1.5039-1.5039-0.82031 0-1.5039 0.66406-1.5039 1.5039v12.656h-12.656c-0.82031 0-1.5039 0.66406-1.5039 1.5039 0 0.82031 0.66406 1.5039 1.5039 1.5039h12.656v12.656c0 0.82031 0.66406 1.5039 1.5039 1.5039 0.82031 0 1.5039-0.66406 1.5039-1.5039v-12.656h12.656c0.82031 0 1.5039-0.66406 1.5039-1.5039 0-0.82031-0.68359-1.5039-1.5039-1.5039z" />
            </g>
        </svg>
    ),
});

export const SaveFilledIcon = createIcon({
    ...defaults,
    displayName: 'SaveOutlineIcon',
    d: 'M43.5 48c-.4 0-.8-.2-1.1-.4L24 28.9 5.6 47.6c-.4.4-1.1.6-1.6.3-.6-.2-1-.8-1-1.4v-45C3 .7 3.7 0 4.5 0h39c.8 0 1.5.7 1.5 1.5v45c0 .6-.4 1.2-.9 1.4-.2.1-.4.1-.6.1z',
});

export const SaveOutlineIcon = createIcon({
    ...defaults,
    displayName: 'SaveOutlineIcon',
    d: 'M43.5 48c-.4 0-.8-.2-1.1-.4L24 29 5.6 47.6c-.4.4-1.1.6-1.6.3-.6-.2-1-.8-1-1.4v-45C3 .7 3.7 0 4.5 0h39c.8 0 1.5.7 1.5 1.5v45c0 .6-.4 1.2-.9 1.4-.2.1-.4.1-.6.1zM24 26c.8 0 1.6.3 2.2.9l15.8 16V3H6v39.9l15.8-16c.6-.6 1.4-.9 2.2-.9z',
});

export const MessageIcon = createIcon({
    ...defaults,
    displayName: 'MessageIcon',
    viewBox: '0 0 32 32',
    d: 'M30 1.25h-28c-0.414 0-0.75 0.336-0.75 0.75v0 22c0 0.414 0.336 0.75 0.75 0.75h7.25v5.25c0 0.292 0.167 0.545 0.411 0.668l0.004 0.002c0.096 0.050 0.21 0.080 0.331 0.080 0.001 0 0.003 0 0.004 0h-0c0.17-0 0.326-0.057 0.452-0.152l-0.002 0.001 7.8-5.85h11.75c0.414-0 0.75-0.336 0.75-0.75v0-22c-0-0.414-0.336-0.75-0.75-0.75v0zM29.25 23.25h-11.25c-0.17 0-0.326 0.057-0.452 0.152l0.002-0.001-6.8 5.1v-4.5c-0-0.414-0.336-0.75-0.75-0.75h-7.25v-20.5h26.5zM6 9.75h20c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0h-20c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0zM6 16.75h10c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0h-10c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0z',
});

export const MessageFilledIcon = createIcon({
    ...defaults,
    displayName: 'MessageFilledIcon',
    viewBox: '0 0 100 100',
    d: 'm11.699 76.41h0.011719l11.34-0.089844 0.058594 7.5195c0 0.60156 0.37109 1.1406 0.92969 1.3711 0.17969 0.078124 0.37891 0.12109 0.57031 0.12109 0.39062 0 0.76953-0.16016 1.0586-0.44141l8.6992-8.6602 54.41-0.44141c0.82812-0.011718 1.4883-0.69141 1.4883-1.5117l-0.46875-58.121c-0.011719-0.39844-0.17188-0.78125-0.44922-1.0586-0.28125-0.26953-0.62891-0.39844-1.0586-0.42969l-77.07 0.62891c-0.82812 0-1.4883 0.67969-1.4883 1.5117l0.46875 58.109c0.011719 0.82812 0.67969 1.4883 1.5 1.4883zm54.855-35.332c2.1641 0 3.9219 1.7539 3.9219 3.9219 0 2.1641-1.7539 3.9219-3.9219 3.9219-2.1641 0-3.9219-1.7539-3.9219-3.9219 0-2.1641 1.7539-3.9219 3.9219-3.9219zm-16.555 0c2.1641 0 3.9219 1.7539 3.9219 3.9219 0 2.1641-1.7539 3.9219-3.9219 3.9219-2.1641 0-3.9219-1.7539-3.9219-3.9219 0-2.1641 1.7539-3.9219 3.9219-3.9219zm-16.555 0c2.1641 0 3.9219 1.7539 3.9219 3.9219 0 2.1641-1.7539 3.9219-3.9219 3.9219-2.1641 0-3.9219-1.7539-3.9219-3.9219 0-2.1641 1.7539-3.9219 3.9219-3.9219z',
});

export const MessageOutlineIcon = createIcon({
    ...defaults,
    displayName: 'MessageOutlineIcon',
    viewBox: '0 0 105 105', //TODO: find better icons
    path: (
        <svg fill="currentColor">
            <g>
                <path d="m11.703 76.41h0.011719l11.336-0.089844 0.0625 7.5195c0.003907 0.60547 0.37109 1.1445 0.92969 1.375 0.18359 0.074218 0.37891 0.11328 0.57031 0.11328 0.39062 0 0.76953-0.15234 1.0586-0.4375l8.7031-8.6602 54.406-0.4375c0.82812-0.007813 1.4961-0.68359 1.4883-1.5117l-0.46875-58.113c-0.003906-0.39844-0.16406-0.77734-0.44922-1.0586-0.28125-0.27734-0.62891-0.40234-1.0625-0.42969l-77.062 0.62109c-0.82812 0.007813-1.4961 0.68359-1.4883 1.5117l0.46875 58.113c0.007813 0.82422 0.67578 1.4883 1.5 1.4883zm75.105-58.723 0.44531 55.113-53.52 0.43359c-0.39453 0.003906-0.76953 0.16016-1.0469 0.4375l-6.6094 6.5742-0.042969-5.4492c-0.003906-0.39844-0.16406-0.77734-0.44922-1.0586-0.28125-0.27734-0.65625-0.42969-1.0508-0.42969h-0.011718l-11.336 0.089844-0.44531-55.113 74.062-0.59766z" />
                <path d="m37.367 45c0 5.2266-7.8438 5.2266-7.8438 0s7.8438-5.2266 7.8438 0" />
                <path d="m53.922 45c0 5.2266-7.8438 5.2266-7.8438 0s7.8438-5.2266 7.8438 0" />
                <path d="m70.477 45c0 5.2266-7.8438 5.2266-7.8438 0s7.8438-5.2266 7.8438 0" />
            </g>
        </svg>
    ),
});

export const BackIcon = createIcon({
    ...defaults,
    displayName: 'MessageIcon',
    viewBox: '0 0 1024 1024',
    d: 'M810.666667 469.333333H304.64l154.88-186.026666a42.666667 42.666667 0 1 0-65.706667-54.613334l-213.333333 256a50.773333 50.773333 0 0 0-3.84 6.4c0 2.133333 0 3.413333-2.986667 5.546667A42.666667 42.666667 0 0 0 170.666667 512a42.666667 42.666667 0 0 0 2.986666 15.36c0 2.133333 0 3.413333 2.986667 5.546667a50.773333 50.773333 0 0 0 3.84 6.4l213.333333 256A42.666667 42.666667 0 0 0 426.666667 810.666667a42.666667 42.666667 0 0 0 27.306666-9.813334 42.666667 42.666667 0 0 0 5.546667-60.16L304.64 554.666667H810.666667a42.666667 42.666667 0 0 0 0-85.333334z',
});

export const Arrow = createIcon({
    displayName: 'Arrow',
    viewBox: '0 0 72 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
            fill="currentColor"
        />
    ),
});

export const PhotoOutlineIcon = createIcon({
    ...defaults,
    displayName: 'PhotoOutlineIcon',
    viewBox: '0 0 48 48', //TODO: find better icons
    path: (
        <svg fill="currentColor">
            <path d="M34.78,38.5H13.22A1.24,1.24,0,0,1,12,37.24v-5C12,28,17.38,24.5,24,24.5S36,28,36,32.26v5A1.24,1.24,0,0,1,34.78,38.5ZM24,25.5c-6.07,0-11,3-11,6.76v5a0.25,0.25,0,0,0,.22.26H34.78A0.25,0.25,0,0,0,35,37.24v-5C35,28.53,30.07,25.5,24,25.5Z" />
            <path d="M24,22.5a6,6,0,1,1,6-6A6,6,0,0,1,24,22.5Zm0-11a5,5,0,1,0,5,5A5,5,0,0,0,24,11.5Z" />
            <path d="M43.5,45H4.5A4.5,4.5,0,0,1,0,40.5V7.5A4.5,4.5,0,0,1,4.5,3h39A4.5,4.5,0,0,1,48,7.5v33A4.5,4.5,0,0,1,43.5,45ZM4.5,4A3.5,3.5,0,0,0,1,7.5v33A3.5,3.5,0,0,0,4.5,44h39A3.5,3.5,0,0,0,47,40.5V7.5A3.5,3.5,0,0,0,43.5,4H4.5Z" />
            <rect width="48" height="48" fill="none" />
        </svg>
    ),
});

export const IosShareIcon = createIcon({
    ...defaults,
    displayName: 'IosShareIcon',
    viewBox: '0 0 24 24',
    path: (
        <svg fill="currentColor">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z" />
        </svg>
    ),
});
