import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, IconButtonProps, IconProps } from '@chakra-ui/react';

import * as Icon from '../Icon/Icon';

type ToggleButtonInstanceProps = Omit<IconButtonProps, 'icon' | 'aria-label'> & {
    isToggled?: boolean;
    iconProps?: IconProps;
};

type ToggleButtonProps = ToggleButtonInstanceProps & {
    icons: [toggled: React.ReactElement, untoggled: React.ReactElement];
    labels: [toggled: string, untoggled: string];
};

export type { ToggleButtonInstanceProps as ToggleButtonProps };

export const ToggleButton: React.FC<ToggleButtonProps> = ({
    icons,
    labels,
    isToggled,
    iconProps,
    ...iconButtonProps
}) => {
    const { t } = useTranslation();

    const index = isToggled ? 0 : 1;

    const props: IconButtonProps = {
        ...iconButtonProps,
        'aria-label': t(labels[index]),
        icon: icons[index],
        outline: 'none',
    };

    return <IconButton size="md" {...props} />;
};

export const SaveButton: React.FC<ToggleButtonInstanceProps> = ({ isToggled = false, iconProps, ...buttonProps }) => (
    <ToggleButton
        isToggled={isToggled}
        {...buttonProps}
        icons={[
            <Icon.SaveFilledIcon {...(iconProps as any)} key="toggled" />,
            <Icon.SaveOutlineIcon {...(iconProps as any)} key="untoggled" />,
        ]}
        labels={['Remove', 'Save']}
    />
);

export const HomeButton: React.FC<ToggleButtonInstanceProps> = ({ isToggled = false, iconProps, ...buttonProps }) => {
    return (
        <ToggleButton
            isToggled={isToggled}
            {...buttonProps}
            icons={[
                <Icon.HomeFilledIcon {...iconProps} key="toggled" />,
                <Icon.HomeOutlineIcon {...iconProps} key="untoggled" />,
            ]}
            labels={['Home', 'Away']}
            transform={'scale(1.3)'} //TODO: find better icons
        />
    );
};

export const MessageButton: React.FC<ToggleButtonInstanceProps> = ({
    isToggled = false,
    iconProps,
    ...buttonProps
}) => (
    <ToggleButton
        isToggled={isToggled}
        {...buttonProps}
        paddingTop={'3px'}
        icons={[
            <Icon.MessageFilledIcon {...iconProps} key="toggled" />,
            <Icon.MessageOutlineIcon {...iconProps} key="untoggled" />,
        ]}
        labels={['Message', 'NoMessage']}
    />
);

export const LoveButton: React.FC<ToggleButtonInstanceProps> = ({ isToggled = false, iconProps, ...buttonProps }) => (
    <ToggleButton
        isToggled={isToggled}
        {...buttonProps}
        icons={[
            <Icon.LoveFilledIcon {...iconProps} key="toggled" />,
            <Icon.LoveOutlineIcon {...iconProps} key="untoggled" />,
        ]}
        labels={['Message', 'NoMessage']}
    />
);
