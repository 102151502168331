import { IconButton, IconButtonProps, IconProps, forwardRef } from '@chakra-ui/react';
import React from 'react';

import * as Icon from '../Icon/Icon';

export type ButtonProps = {
    buttonProps?: Omit<IconButtonProps, 'aria-label'>;
    iconProps?: IconProps;
};

export const LikedPostsButton: React.FC<ButtonProps> = ({ buttonProps, iconProps }) => (
    <IconButton
        size="md"
        {...buttonProps}
        aria-label="Activity feed"
        icon={<Icon.HeartOutlineIcon {...(iconProps as any)} />}
    />
);

export const CommentButton: React.FC<ButtonProps> = ({ buttonProps, iconProps }) => (
    <IconButton size="md" {...buttonProps} aria-label="Comment" icon={<Icon.CommentIcon {...(iconProps as any)} />} />
);

export const ShareButton: React.FC<ButtonProps> = ({ buttonProps, iconProps }) => (
    <IconButton
        size="md"
        {...buttonProps}
        aria-label="Direct messages"
        icon={<Icon.DirectMessagesIcon {...(iconProps as any)} />}
    />
);

export const EllipsisButton: React.FC<ButtonProps> = forwardRef((buttonProps, ref) => {
    return <IconButton size="md" {...buttonProps} aria-label="More options" ref={ref} icon={<Icon.EllipsisIcon />} />;
});

export const EmojiButton: React.FC<ButtonProps> = ({ buttonProps, iconProps }) => (
    <IconButton size="md" {...buttonProps} aria-label="Emoji" icon={<Icon.EmojiIcon {...(iconProps as any)} />} />
);

export const FindPeopleButton: React.FC<ButtonProps> = ({ buttonProps, iconProps }) => (
    <IconButton
        size="md"
        {...buttonProps}
        aria-label="Find people"
        icon={<Icon.FindPeopleIcon {...(iconProps as any)} />}
    />
);

export const NewPostButton: React.FC<ButtonProps> = ({ buttonProps, iconProps }) => (
    <IconButton size="md" {...buttonProps} aria-label="New post" icon={<Icon.NewPostIcon {...(iconProps as any)} />} />
);

export const BackButton: React.FC<ButtonProps> = ({ buttonProps, iconProps }) => (
    <IconButton size="md" {...buttonProps} aria-label="Back" icon={<Icon.BackIcon {...(iconProps as any)} />} />
);
