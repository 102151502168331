import { focusStyles, skeletonProps } from './utils';

export const Button = {
    defaultProps: {
        variant: 'plain',
        size: 'xs',
    },
    variants: {
        plain: {
            bg: 'none',
            color: 'mode.text1',
            _hover: {
                bg: 'none',
            },
            ...focusStyles,
        },
        link: {
            _hover: { bg: 'none' },
            color: 'mode.text4',
            size: 'xs',
            variant: 'ghost',
        },
        linkRed: {
            _hover: { bg: 'none' },
            color: 'red',
            size: 'xs',
            variant: 'ghost',
        },
        cta: {
            bg: 'mode.text4',
            color: 'mode.backgroundAlt',
            size: 'full',
            fontSize: '--chakra-fontSizes-md',
            variant: 'solid',
            _hover: {
                _disabled: {
                    bg: 'mode.text4',
                },
            },
        },
    },
};

export const Container = {
    baseStyle: {
        maxW: 975,
    },
    variants: {
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 3,
            height: '100%',
            width: '100%',
            bg: 'mode.overlay',
            display: 'flex',
        },
        storyOverlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            bg: 'rgba(0, 0, 0, .5)',
            display: 'flex',
        },
    },
};

export const Heading = {
    baseStyle: {
        color: 'mode.text1',
    },
};

export const Input = {
    baseStyle: {
        field: {
            bg: 'mode.background',
        },
    },
};

export const Link = {
    variants: {
        profile: {
            color: 'mode.text1',
            fontWeight: 'semibold',
        },
        profileSmall: {
            color: 'mode.text1',
            fontWeight: 'semibold',
            fontSize: 'small',
        },
    },
};

export const Skeleton = {
    defaultProps: {
        ...skeletonProps,
        w: 'fit-content',
    },
};

export const Text = {
    baseStyle: {
        color: 'mode.text1',
    },
    variants: {
        logo: {
            _hover: {},
        },
        profile: {
            color: 'mode.text1',
            fontWeight: 'semibold',
        },
        title: {
            fontWeight: 'semibold',
            fontSize: 'x-large',
            color: 'mode.text3',
        },
        overlay: {
            color: 'mode.text1',
            fontWeight: 'semibold',
            zIndex: 3,
        },
        small: {
            fontSize: 'small',
        },
        editable: {
            bg: 'rgba(0, 0, 0, .05)',
            height: 'var(--chakra-sizes-10)',
            lineHeight: 'var(--chakra-sizes-10)',
            paddingLeft: '10px',
        },
    },
};

export const Divider = {
    baseStyle: {
        color: 'mode.text3',
    },
};
