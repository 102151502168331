import React, { ReactNode, useState } from 'react';
import { UserData, getUserData } from '../util/AuthService';

export let UserContext: React.Context<{
    user: UserData | undefined;
    setUser: React.Dispatch<React.SetStateAction<UserData | undefined>>;
}>;

export const UserContextWrapper = ({ children }: { children: ReactNode }) => {
    const existingUserData = getUserData() as UserData;
    const [user, setUser] = useState<UserData | undefined>(
        existingUserData
            ? {
                  userEmail: existingUserData.userEmail,
                  userName: existingUserData.userName,
                  userId: existingUserData.userId,
                  userType: existingUserData.userType,
              }
            : undefined,
    );

    UserContext = React.createContext({ user, setUser });
    return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
