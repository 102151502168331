import React, { useContext, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    BoxProps,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Portal,
    Spacer,
    Stack,
    StackProps,
    Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserType } from '../../common/constants';
import { PostRemoteDto } from '../../common/types/PostRemoteDto';
import { getInfluencerPhoto } from '../../util/AuthService';
import { CommentButton, ShareButton, EllipsisButton } from '../Button/Button';
import { LikeButton } from '../ToggleButton/LikeButton';
import { deletePost } from '../../util/ApiService';
import { UserContext } from '../../router/UserContext';
import { AppContext } from '../App/AppContext';

export const Header: React.FC<PostRemoteDto> = ({ id: postId }) => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const [isLoading, setLoading] = useState<boolean>(false);
    const { appData } = useContext(AppContext);

    const [appPhoto, setAppPhoto] = useState<string>();
    useEffect(() => {
        async function _() {
            setAppPhoto(await getInfluencerPhoto());
        }
        _();
    }, []);

    const queryClient = useQueryClient();
    const deletePostMutation = useMutation<unknown, unknown, { postId: string }>({
        mutationFn: deletePost,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getFeed'] });
            queryClient.invalidateQueries({ queryKey: ['getStories'] });
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        },
    });

    const deletePhoto = () => {
        setLoading(true);
        deletePostMutation.mutate({ postId });
    };

    return (
        <Stack align="center" direction="row" p={2} spacing={3}>
            <Box layerStyle="avatarBorder">
                <Avatar name={appData?.influencerName} size="sm" src={appPhoto} />
            </Box>
            <Text flex={1} variant="profile">
                {appData?.influencerName}
            </Text>

            {user?.userType === UserType.INFLUENCER && (
                <Box>
                    <Menu isLazy>
                        <MenuButton as={EllipsisButton} />
                        <Portal>
                            <MenuList background={'mode.background'}>
                                <MenuItem disabled={isLoading} onClick={deletePhoto}>
                                    {t('Delete')}
                                </MenuItem>
                            </MenuList>
                        </Portal>
                    </Menu>
                </Box>
            )}
        </Stack>
    );
};

export const ActionButtons: React.FC<
    StackProps & {
        isBlurred: boolean;
        isLoading: boolean;
        doILikePost: boolean;
        commentClickAction?: () => unknown;
        likeUnlikeAction?: (n: boolean) => unknown;
    }
> = ({ doILikePost, isBlurred, commentClickAction, likeUnlikeAction, isLoading, ...stackProps }) => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const { appData } = useContext(AppContext);

    const shareClick = async () => {
        await navigator.share({
            title: appData?.appName,
            text: t('Check out this post by {{name}}', { name: appData?.influencerName }),
            url: window.location.href,
        });
    };

    return (
        <Stack direction="row" p={1} spacing={0} {...stackProps}>
            {user?.userType === UserType.SUBSCRIBER && !isBlurred && (
                <LikeButton isLiked={doILikePost} isLoading={isLoading} likeUnlikeAction={likeUnlikeAction} />
            )}
            <CommentButton buttonProps={{ onClick: commentClickAction }} />
            <Spacer />
            <ShareButton buttonProps={{ onClick: shareClick }} />
        </Stack>
    );
};

export const Likes: React.FC<Pick<PostRemoteDto, 'likesCount'>> = ({ likesCount }) => {
    const { t } = useTranslation();

    return likesCount > 0 ? (
        <Text cursor="pointer" fontWeight="semibold" px={4}>
            {t(`{{count}} ${likesCount === 1 ? 'like' : 'likes'}`, { count: likesCount })}
        </Text>
    ) : null;
};

export const Caption: React.FC<BoxProps & { text: string }> = ({ text, ...boxProps }) => {
    const { appData } = useContext(AppContext);

    return (
        <Box {...boxProps}>
            <Text className="text-content">
                <Link variant={'profile'}>{appData?.influencerName}</Link> {text}
            </Text>
        </Box>
    );
};
