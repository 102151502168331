import { lazy, useContext } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import { GuardedRoute } from './GuardedRoute';
import { UserContext } from './UserContext';
import { Home, LikesHome } from '../components/Home';
import { AppContainer, NavContainer } from '../components/App';

const InfluencerLogin = lazy(() => import('../components/Auth/InfluencerLogin'));
const SubscriberLogin = lazy(() => import('../components/Auth/SubscriberLogin'));
const SubscriberRegister = lazy(() => import('../components/Auth/SubscriberRegister'));
const NewPostPage = lazy(() => import('../components/NewPost/NewPost'));
const ChatListComponent = lazy(() => import('../components/Chat/ChatListComponent'));
const ChatPage = lazy(() => import('../components/Chat/ChatPage'));
const CommentPage = lazy(() => import('../components/Comments/AllComments'));
const SubscribePage = lazy(() => import('../components/Subscription/Subscribe'));
const VerifyEmail = lazy(() => import('../components/Auth/VerifyEmail'));
const StoryPage = lazy(() => import('../components/Stories/StoryPage'));
const Settings = lazy(() => import('../components/Settings/Settings'));

function isNumeric(str: string) {
    if (typeof str != 'string') return false; // we only process strings!
    return (
        !isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
}

export const Router = () => {
    const { user } = useContext(UserContext);
    let baseName: string | undefined = undefined;
    if (isNumeric(window.location.hostname.split('.')[0])) {
        baseName = window.location.pathname.split('/').filter((element) => element)[0];
    }

    return (
        <BrowserRouter basename={baseName}>
            <Routes>
                <Route element={<GuardedRoute isRouteAccessible={!user} redirectRoute={'home'} />}>
                    <Route path="sudo" element={<InfluencerLogin />} />
                    <Route path="login" element={<SubscriberLogin />} />
                    <Route path="register" element={<SubscriberRegister />} />
                    <Route path="verify" element={<VerifyEmail />} />
                </Route>

                <Route element={<GuardedRoute isRouteAccessible={!!user} redirectRoute={'login'} />}>
                    <Route element={<AppContainer />}>
                        <Route path="add" element={<NewPostPage />} />
                        <Route path="stories" element={<Home />} />
                        <Route path=":postId/comments" element={<CommentPage />} />

                        <Route path="chat" element={<ChatPage />} />
                        <Route path="chat/:chatId" element={<ChatPage />} />
                        <Route path="chats" element={<ChatListComponent />} />

                        <Route path="story/:id" element={<StoryPage />} />

                        <Route element={<NavContainer />}>
                            <Route path="home" element={<Home />} />
                            <Route path="likes" element={<LikesHome />} />
                            <Route path="subscribe/:planId" element={<SubscribePage />} />

                            <Route path="settings" element={<Settings />} />
                        </Route>
                    </Route>
                </Route>

                <Route index element={<Navigate to={'home'} replace />} />
                <Route path="*" element={<Navigate to={'home'} replace />} />
            </Routes>
        </BrowserRouter>
    );
};
