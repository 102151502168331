import React from 'react';
import { Center, Container, Image, Text } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import ReactPlayer from 'react-player';

import fallbackImage from '../../resources/fallback.jpg';
import { PostFileData } from '../../common/types/PostRemoteDto';

export const MultiMediaPost: React.FC<{ caption: string; content: PostFileData[]; doubleTap?: () => void }> = ({
    caption,
    content,
    doubleTap,
}) => {
    return (
        <Swiper
            modules={[Pagination]}
            pagination={{ enabled: true, type: 'bullets' }}
            // virtual={{ enabled: true, cache: true }}
            onDoubleClick={doubleTap}
            lazyPreloadPrevNext={2}
        >
            {content.map((item, index) => (
                <SwiperSlide key={`${item.id}`}>
                    <Center height={'100%'} bg="mode.background" minH={50}>
                        {(() => {
                            if (item.type === 'IMAGE') {
                                return (
                                    <PostImage
                                        URL={item.contentData.URL}
                                        thumbnailURL={item.contentData.thumbnailURL}
                                        caption={content.length > 1 ? `${caption} ${index}` : caption}
                                        width={item.contentData.width}
                                        lazyLoad={index !== 0}
                                    />
                                );
                            } else if (item.type === 'VIDEO') {
                                return (
                                    <PostVideo
                                        thumbnailURL={item.contentData.thumbnailURL}
                                        videoURL={item.contentData.videoURL}
                                        caption={`${caption} ${index}`}
                                        lazyLoad={index !== 0}
                                    />
                                );
                            }

                            return null;
                        })()}
                    </Center>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export const PostImage: React.FC<{
    caption?: string;
    URL: string;
    thumbnailURL: string;
    width: number;
    lazyLoad: boolean;
}> = ({ caption, URL, thumbnailURL, width, lazyLoad }) => {
    return (
        <Image
            alt={caption}
            src={URL ?? fallbackImage}
            srcSet={thumbnailURL ? `${thumbnailURL} 350w, ${URL} ${width}w` : ``}
            sizes="(max-width: 950px) 100vw, 950px"
            objectFit="contain"
            width={'100%'}
            loading={lazyLoad ? 'lazy' : 'eager'}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = fallbackImage;
            }}
        />
    );
};

export const PostVideo: React.FC<{
    caption?: string;
    thumbnailURL: string;
    videoURL?: string;
    lazyLoad: boolean;
}> = ({ caption, thumbnailURL, videoURL, lazyLoad }) => {
    if (videoURL) {
        return (
            <Container bg="mode.background" p={0}>
                <ReactPlayer
                    url={videoURL}
                    // light={thumbnailURL}
                    stopOnUnmount={true}
                    loop={true}
                    playing={true}
                    width={'100%'}
                    height={'100%'}
                    volume={0}
                    muted={true}
                    controls={false}
                    playsinline={true}
                />
            </Container>
        );
    } else if (thumbnailURL) {
        return (
            <Image
                alt={caption}
                src={thumbnailURL}
                objectFit="contain"
                width={'100%'}
                loading={lazyLoad ? 'lazy' : 'eager'}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = fallbackImage;
                }}
            />
        );
    } else
        return (
            <Center p={5}>
                <Text>Video temporarily unavailable</Text>
            </Center>
        );
};
