import { Box, Container, Flex, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import * as Button from '../Button/Button';
import { doLogout } from '../../util/ApiService';
import { UserType } from '../../common/constants';
import { ProfileButton } from '../ProfileButton/ProfileButton';
import { HomeButton, LoveButton, MessageButton } from '../../components/ToggleButton/ToggleButton';
import { UserContext } from '../../router/UserContext';
import { AppContext } from '../App/AppContext';

export const NAVBAR_HEIGHT = 14;
export const NavBar: React.FC = () => (
    <Flex
        align="center"
        as="header"
        bg="mode.backgroundAlt"
        borderBottomColor="mode.border"
        borderBottomWidth="1px"
        h={NAVBAR_HEIGHT}
        justify="space-between"
        left={0}
        pos="fixed"
        px={1}
        py={1}
        right={0}
        zIndex="banner"
    >
        <Container px={0}>
            <Stack align="center" direction="row" spacing={2}>
                <Brand />
                {/* <Search /> */}
                <Nav />
            </Stack>
        </Container>
    </Flex>
);

const Brand: React.FC = () => {
    const { appData } = useContext(AppContext);

    return (
        <Flex flex={1}>
            <Text fontFamily={'MakeTheBest'} fontSize={'4xl'} variant={'logo'} px={1}>
                {appData?.appName}
            </Text>
        </Flex>
    );
};

// const Search: React.FC = () => (
//     <Flex flex={1} justify="center">
//         <Input maxW="215px" placeholder="Search" size="sm" textAlign="center" />
//     </Flex>
// );

const Nav: React.FC = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const navigate: NavigateFunction = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const { pathname } = useLocation();

    const logout = () => {
        doLogout();
        queryClient.clear();
        setUser(undefined);
        navigate('/login');
    };

    const addPost = () => {
        navigate('/add');
    };

    const goHome = () => {
        navigate('/home');
    };

    const goBookmarks = () => {
        navigate('/likes');
    };

    const goChat = () => {
        if (user?.userType === UserType.INFLUENCER) navigate('/chats');
        else navigate('/chat');
    };

    const goSettings = () => {
        navigate('/settings');
    };

    return (
        <Stack as="nav" direction="row" flex={1} justifyContent="flex-end" spacing={2}>
            <HomeButton onClick={goHome} isToggled={pathname === '/home'} />

            <MessageButton onClick={goChat} isToggled={pathname.includes('/chat')} />

            {user?.userType === UserType.INFLUENCER && <Button.NewPostButton buttonProps={{ onClick: addPost }} />}
            {user?.userType === UserType.SUBSCRIBER && (
                <LoveButton onClick={goBookmarks} isToggled={pathname === '/likes'} />
            )}

            <Box>
                <Menu isLazy>
                    <MenuButton as={ProfileButton} />
                    <MenuList background={'mode.background'}>
                        <MenuItem onClick={goSettings}>{t('Settings')}</MenuItem>
                        <MenuItem onClick={logout}>{t('Logout')}</MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        </Stack>
    );
};
