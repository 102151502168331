import React, { useContext, useEffect, useState } from 'react';
import { Avatar, forwardRef, IconButton, IconButtonProps } from '@chakra-ui/react';

import { getUserPhoto } from '../../util/AuthService';
import { UserContext } from '../../router/UserContext';

export type ButtonProps = Omit<IconButtonProps, 'aria-label'>;

export const ProfileButton: React.FC<ButtonProps> = forwardRef((buttonProps, ref) => {
    const { user } = useContext(UserContext);
    const [userPhoto, setUserPhoto] = useState<string>();
    useEffect(() => {
        async function _() {
            setUserPhoto(await getUserPhoto());
        }
        _();
    }, []);

    return (
        <IconButton
            size="md"
            {...buttonProps}
            aria-label="Profile menu"
            ref={ref}
            icon={<Avatar bg="mode.background" name={user?.userName} size="xs" src={userPhoto} borderWidth={1} />}
        />
    );
});
