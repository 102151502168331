import React, { useState } from 'react';
import io from 'socket.io-client';

import { Router } from '../../router/router';
import { WEBSOCKET_PATH } from '../../common/constants';
import { UserContextWrapper } from '../../router/UserContext';
import { isBrowserViewAllowed, isPWA } from '../../util/PWA';
import InvokeUserPassionToInstallComponent from '../Landing';
import { AnimatedLoadingIcon } from '../Icon/LottieIcon';
import { getHeaders } from '../../util/ApiService';

const socketSingleton = io({
    path: WEBSOCKET_PATH,
    extraHeaders: {
        ...getHeaders(),
    },
});
export let SocketContext: React.Context<{
    socket: typeof socketSingleton;
}>;

export const App = () => {
    if (isPWA() || isBrowserViewAllowed()) {
        const [socket] = useState<typeof socketSingleton>(socketSingleton);
        SocketContext = React.createContext({ socket });

        return (
            <React.Suspense fallback={<AnimatedLoadingIcon />}>
                <UserContextWrapper>
                    <SocketContext.Provider value={{ socket }}>
                        <Router />
                    </SocketContext.Provider>
                </UserContextWrapper>
            </React.Suspense>
        );
    } else
        return (
            <React.Suspense fallback={<AnimatedLoadingIcon />}>
                <InvokeUserPassionToInstallComponent />
            </React.Suspense>
        );
};
