import isDev from './DevDetect';

export function isPWA() {
    return (
        ['fullscreen', 'standalone', 'minimal-ui'].some(
            (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) || (window.navigator as any)?.standalone === true
    );
}

export const allowBrowserView = () => {
    localStorage.setItem('browserViewAllowed', '1');
    localStorage.setItem('browserViewAllowedSetOn', new Date().toString());
};

export const isBrowserViewAllowed = () => {
    if (localStorage.getItem('browserViewAllowed') === null) return false;

    //Time interval in minutes before showing install screen again
    const showInstallScreenWaitTime = isDev() ? 250 : 60;

    const browserViewAllowedSetOn = new Date(localStorage.getItem('browserViewAllowedSetOn') as string);
    const browserViewAllowedExpiresOn = new Date(browserViewAllowedSetOn.getTime() + showInstallScreenWaitTime * 60000);
    if (browserViewAllowedExpiresOn <= new Date()) {
        localStorage.removeItem('browserViewAllowed');
        localStorage.removeItem('browserViewAllowedSetOn');
        return false;
    }

    return localStorage.getItem('browserViewAllowed') === '1';
};

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
export interface BeforeInstallPromptEvent extends Event {
    /**
     * Returns an array of DOMString items containing the platforms on which the event was dispatched.
     * This is provided for user agents that want to present a choice of versions to the user such as,
     * for example, "web" or "play" which would allow the user to chose between a web version or
     * an Android version.
     */
    readonly platforms: Array<string>;

    /**
     * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
     */
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;

    /**
     * Allows a developer to show the install prompt at a time of their own choosing.
     * This method returns a Promise.
     */
    prompt(): Promise<void>;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

export const setBrowserSize = (width: number, height: number) => {
    if (isPWA()) {
        // Size window after open the app
        window.resizeTo(width, height);

        window.addEventListener('resize', () => {
            window.resizeTo(width, height);
            // const { innerHeight, innerWidth } = window;
            // console.log({ innerHeight, innerWidth });
        });
    }
};
