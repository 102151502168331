import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

export const BlogPost: React.FC<{ heading: string; content: string; doubleTap?: () => void }> = ({
    heading,
    content,
    doubleTap,
}) => {
    return (
        <Box p={3} onDoubleClick={doubleTap}>
            <Heading as="h4" size="md">
                {heading}
            </Heading>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </Box>
    );
};
