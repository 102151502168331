import { Box, Container, Flex, Stack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import React from 'react';

import { ReactProps } from '../../common/types/ReactProps';
import { AppContextWrapper } from './AppContext';
import { NAVBAR_HEIGHT, NavBar } from '../NavBar';
import { AnimatedLoadingIcon } from '../Icon/LottieIcon';

export const AppContainer: React.FC = () => {
    return (
        <AppShell>
            <AppContextWrapper>
                <React.Suspense fallback={<AppSkeleton />}>
                    <Outlet />
                </React.Suspense>
            </AppContextWrapper>
        </AppShell>
    );
};

export const NavContainer: React.FC = () => (
    <NavShell>
        <React.Suspense fallback={<AnimatedLoadingIcon />}>
            <Outlet />
        </React.Suspense>
    </NavShell>
);

const AppShell: React.FC<ReactProps> = ({ children }) => (
    <Stack bottom={0} flex={1} left={0} pos="absolute" right={0} spacing={0} top={0} height="100vh">
        {children}
    </Stack>
);

const NavShell: React.FC<ReactProps> = ({ children }) => (
    <Stack spacing={0}>
        <NavBar />
        <Flex as="main" bg="mode.background" flex={1}>
            <Container flex={1} px={0} minH="100vh" paddingTop={NAVBAR_HEIGHT} overflow="auto">
                <Stack direction="row" flex={1} spacing={8} minH="100%" width={'100%'}>
                    {children}
                </Stack>
            </Container>
        </Flex>
    </Stack>
);

const AppSkeleton: React.FC = () => {
    return (
        <Container py={5}>
            <Stack
                bg="mode.backgroundAlt"
                borderColor="mode.border"
                borderWidth="1px"
                flex={1}
                fontSize="sm"
                layerStyle="loading"
                spacing={5}
            >
                {new Array(10).fill(0).map((_, i) => (
                    <Box key={i}>
                        <Box layerStyle="skeleton" w="100%" />
                        <Box layerStyle="skeleton" w="80%" />
                    </Box>
                ))}
            </Stack>
        </Container>
    );
};
