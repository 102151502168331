import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import localForage from 'localforage';

import { initLocale } from './i18n';
import { setBrowserSize } from './util/PWA';
import reportWebVitals from './reportWebVitals';
import { init } from './util/ApiService';
import isDev from './util/DevDetect';

import 'swiper/css';
import 'swiper/css/pagination';
import './index.css';
import { Flex } from '@chakra-ui/react';
import { App } from './components/App/App';
import { AppThemeProvider } from './components/ThemeProvider/ThemeProvider';
import { ColorModeSwitcher } from './components/ColorModeSwitcher/ColorModeSwitcher';

localForage.config({
    driver: [localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE],
    name: 'fans-core',
    version: 1,
});

initLocale();

const queryPersister = createSyncStoragePersister({
    storage: window.localStorage,
    throttleTime: 500,
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
            refetchInterval: false,
            refetchOnWindowFocus: false,
            retryDelay: 10000 * 10,
            retry: true,
        },
    },
});

if (!isDev()) {
    Sentry.init({
        dsn: 'https://084b7f2235b4417fb7209d0c586a01e3@o4505135302377472.ingest.sentry.io/4505135303819264',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        environment: window.location.hostname,
        // Performance Monitoring
        tracesSampleRate: 0.3,
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

//App init
init();
setBrowserSize(500, 844);

createRoot(document.getElementById('root') as Element).render(
    <React.StrictMode>
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: queryPersister }}>
            <AppThemeProvider>
                <App />

                <Flex pos="fixed" right={2} bottom={2} zIndex={11} id="colorModeSwitchButton">
                    <ColorModeSwitcher />
                </Flex>
            </AppThemeProvider>

            {/* <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" /> */}
        </PersistQueryClientProvider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
