import { Center } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';

export function AnimatedLoadingIcon() {
    return (
        <Center width={'100%'}>
            <Player
                autoplay
                loop
                src="https://lottie.host/5dfa3af6-e067-4df3-8099-aacb8fedd083/PrMaq469nL.json"
                style={{ height: '300px', width: '100%', paddingBottom: '30px' }}
            />
        </Center>
    );
}
