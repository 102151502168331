import { Box, Heading, Text, Button, Stack, Icon, useColorModeValue, Container } from '@chakra-ui/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { allowBrowserView, BeforeInstallPromptEvent } from '../../util/PWA';
import { getApplication } from '../../util/ApiService';
import { ApplicationRemoteDto } from '../../common/types/ApplicationRemoteDto';
import { Arrow, IosShareIcon } from '../Icon/Icon';

// This variable will save the event for later use.
let deferredPrompt: BeforeInstallPromptEvent | null = null;
window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
    // Prevents the default mini-infobar or install dialog from appearing on mobile
    e.preventDefault();
    // Save the event because you'll need to trigger it later.
    deferredPrompt = e;
});

window.addEventListener('appinstalled', () => {
    window.location.reload();
});

export default function InvokeUserPassionToInstallComponent() {
    const { data: appData } = useSuspenseQuery<ApplicationRemoteDto>({
        queryKey: ['getApplication'],
        queryFn: getApplication,
    });

    const [isIos] = useState<boolean>(() => {
        const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform;

        return (
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    });

    const [isMac] = useState<boolean>(() => {
        // check if it's a mac
        const userAgent = navigator.userAgent.toLowerCase();
        if (navigator.maxTouchPoints || !userAgent.match(/macintosh/)) return false;
        // check safari version >= 17
        const version = /version\/(\d{2})\./.exec(userAgent);
        if (!version || !version[1] || !(parseInt(version[1]) >= 17)) return false;
        // hacky way to detect Sonoma
        const audioCheck = document.createElement('audio').canPlayType('audio/wav; codecs="1"') ? true : false;
        const webGLCheck = new OffscreenCanvas(1, 1).getContext('webgl') ? true : false;

        return audioCheck && webGLCheck;
    });

    const continueOnBrowser = () => {
        allowBrowserView();
        window.location.reload();
    };

    const installApp = async () => {
        if (deferredPrompt == null) return;
        // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
        deferredPrompt.prompt();
        // Find out whether the user confirmed the installation or not
        const { outcome } = await deferredPrompt.userChoice;
        console.log(outcome);
        // The deferredPrompt can only be used once.
        deferredPrompt = null;
        // Act on the user's choice
        if (outcome === 'accepted') {
            console.log('User accepted the install prompt.');
        } else if (outcome === 'dismissed') {
            console.log('User dismissed the install prompt');
        }
    };

    return (
        <Container maxW={'3xl'}>
            <Stack as={Box} textAlign={'center'} py={{ base: 20, md: 36 }}>
                <Heading pb={14} fontWeight={600} fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }} lineHeight={'110%'}>
                    Install and follow <br />
                    <Text as={'span'} color={'mode.green.400'}>
                        {appData.influencerName}
                    </Text>
                </Heading>

                {!isIos && !isMac && (
                    <>
                        <Text pb={14} color={'mode.gray.500'}>
                            The download is secure, painless and blazingly fast. Once you click start below, you can
                            find the app in your home screen and you will not see this page again.
                        </Text>

                        <Stack
                            direction={'column'}
                            spacing={3}
                            align={'center'}
                            alignSelf={'center'}
                            position={'relative'}
                        >
                            <Button
                                colorScheme={'mode.green'}
                                bg={'mode.green.400'}
                                rounded={'full'}
                                px={6}
                                _hover={{
                                    bg: 'mode.green.500',
                                }}
                                onClick={installApp}
                            >
                                Start
                            </Button>
                            <Box>
                                <Icon
                                    as={Arrow}
                                    color={useColorModeValue('mode.gray.800', 'mode.gray.300')}
                                    w={71}
                                    position={'absolute'}
                                    right={-51}
                                    top={'10px'}
                                />
                                <Text
                                    fontSize={'lg'}
                                    fontFamily={'Caveat'}
                                    position={'absolute'}
                                    right={'-105px'}
                                    top={'-15px'}
                                    transform={'rotate(10deg)'}
                                >
                                    It's FREE
                                </Text>
                            </Box>
                        </Stack>
                    </>
                )}

                {isIos && (
                    <>
                        <Text color={'mode.gray.500'}>
                            To be notified whenever {appData.influencerName} makes a new position, install the app by
                            pressing the <IosShareIcon /> icon and clicking "Add to Home Screen". Or
                        </Text>
                    </>
                )}

                {isMac && (
                    <>
                        <Text color={'mode.gray.500'}>
                            To be notified whenever {appData.influencerName} makes a new position, install the app by
                            pressing the <IosShareIcon /> icon in the Navigation bar and then clicking "Add to Dock". Or
                        </Text>
                    </>
                )}

                <Button variant={'link'} colorScheme={'blue'} size={'sm'} onClick={continueOnBrowser}>
                    Continue on browser
                </Button>
            </Stack>
        </Container>
    );
}
