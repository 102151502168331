import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react';
import React from 'react';

import { theme } from '../../theme';
import { ReactProps } from '../../common/types/ReactProps';
import { DynamicColorMode } from './DynamicColorMode';

export const AppThemeProvider: React.FC<ReactProps> = ({ children }) => (
    <>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ChakraProvider theme={extendTheme(theme)}>
            <DynamicColorMode>{children}</DynamicColorMode>
        </ChakraProvider>
    </>
);
