import React from 'react';
import { StackProps } from '@chakra-ui/react';

import { CommentDataRemoteDto } from '../../common/types/CommentRemoteDto';
import { AddCommentBox } from '../Comments/CommentComponent';

export const PostFooter: React.FC<
    StackProps & {
        isBlurred: boolean;
        postId: string;
        commentBoxRef: React.MutableRefObject<HTMLInputElement | null>;
        insertComment?: (comment: CommentDataRemoteDto) => void;
        setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
        isLoading?: boolean;
    }
> = ({ isBlurred, postId, commentBoxRef, insertComment, isLoading, setLoading, ...stackProps }) => {
    return (
        <AddCommentBox
            isBlurred={isBlurred}
            postId={postId}
            commentBoxRef={commentBoxRef}
            insertComment={insertComment}
            setLoading={setLoading}
            isLoading={isLoading}
            {...stackProps}
        />
    );
};
