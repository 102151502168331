import { IconButton, IconButtonProps, IconProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import * as Icon from '../Icon/Icon';

export const LikeButton: React.FC<
    Omit<IconButtonProps, 'icon' | 'aria-label'> & {
        isLiked: boolean;
        likeUnlikeAction?: (n: boolean) => any;
        iconProps?: IconProps;
        isLoading: boolean;
    }
> = ({ isLiked, likeUnlikeAction, iconProps, isLoading, ...buttonProps }) => {
    const { t } = useTranslation();

    return (
        <IconButton
            size="md"
            disabled={isLoading}
            icon={isLiked ? <Icon.HeartFilledIcon {...iconProps} /> : <Icon.HeartOutlineIcon {...iconProps} />}
            aria-label={t(isLiked ? 'unlike' : 'like')}
            onClick={() => {
                if (likeUnlikeAction) likeUnlikeAction(!isLiked);
            }}
            outline="none"
            {...buttonProps}
        />
    );
};
