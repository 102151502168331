import { useSuspenseQuery } from '@tanstack/react-query';
import { Stack, StackProps } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React from 'react';

import { getStories } from '../../util/ApiService';
import { StoryBarProfile, StoryBarProfileSkeleton } from './StoryBarProfile';
import { StoryRemoteDto } from '../../common/types/StoryRemoteDto';
import { PostMinimalRemoteDto } from '../../common/types/PostRemoteDto';

export const StoryBar: React.FC<StackProps> = ({ ...stackProps }) => {
    const { data } = useSuspenseQuery<StoryRemoteDto, AxiosError>({
        queryKey: ['getStories'],
        queryFn: getStories,
        refetchInterval: 600 * 1000,
        gcTime: 300 * 1000,
    });

    //TODO: Story will never error because of suspense. Move this to the suspense error handler
    // if (isLoading) return <StoryBarLoading />;

    // if (error)
    //     return (
    //         <ErrorMessage
    //             name={t('Stories')}
    //             message={(error.response?.data as string | null) || t('Needs interned connection')}
    //             onRetry={refetch}
    //         />
    //     );

    if (data.items.length === 0) return null;
    return <StoryBarLoaded items={data.items} {...stackProps} />;
};

const StoryBarLoaded: React.FC<StackProps & { items: PostMinimalRemoteDto[] }> = ({ items, ...stackProps }) => (
    <Stack
        {...stackProps}
        as="section"
        bg="mode.backgroundAlt"
        borderColor="mode.border"
        borderWidth="1px"
        direction="row"
        overflow="auto"
        scrollBehavior="smooth"
        layerStyle="noScrollBar"
        px={2}
        py={4}
    >
        {items.map((item) => (
            <StoryBarProfile key={item.id} {...item} />
        ))}
    </Stack>
);

const PLACEHOLDER_COUNT = 8;
export const StoryBarLoading: React.FC = () => (
    <Stack
        as="section"
        bg="mode.backgroundAlt"
        borderColor="mode.border"
        borderWidth="1px"
        direction="row"
        layerStyle="loading"
        overflow="hidden"
        px={2}
        py={4}
    >
        {new Array(PLACEHOLDER_COUNT).fill(0).map((_, i) => (
            <StoryBarProfileSkeleton key={i} />
        ))}
    </Stack>
);
