// export const API_URL = 'http://localhost:9000';
// export const API_URL = 'https://api.fan.gallery';
export const API_URL = '/api';
export const WEBSOCKET_PATH = '/socket';

export const APP_CODE = '443145a2-a6a9-415e-9ee3-336a0185aa38';

export const CDN_HOSTNAME = 'cdn.fan.gallery';

export enum UserType {
    INFLUENCER,
    SUBSCRIBER,
}
