import { Stack } from '@chakra-ui/react';
import React from 'react';

import { Feed, FeedLoading } from '../../components/Feed/Feed';
import { StoryBar, StoryBarLoading } from '../../components/Stories/StoryBar';

export const Home: React.FC = () => {
    return (
        <React.Suspense fallback={<HomeLoading />}>
            <Stack as="section" width="100%" spacing={5} py={5}>
                <StoryBar />
                <Feed />
            </Stack>
        </React.Suspense>
    );
};

export const LikesHome: React.FC = () => {
    return (
        <React.Suspense fallback={<LikesLoading />}>
            <Stack as="section" width="100%" spacing={5} py={5}>
                <Feed filterLikes={true} />
            </Stack>
        </React.Suspense>
    );
};

const HomeLoading: React.FC = () => {
    return (
        <Stack as="section" width="100%" spacing={5} py={5}>
            <StoryBarLoading />
            <FeedLoading />
        </Stack>
    );
};

const LikesLoading: React.FC = () => {
    return (
        <Stack as="section" width="100%" spacing={5} py={5}>
            <FeedLoading />
        </Stack>
    );
};
