import { Center, Stack, StackProps, Text } from '@chakra-ui/react';
import { InfiniteData, useSuspenseInfiniteQuery } from '@tanstack/react-query';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import React from 'react';

import { FeedRemoteDto } from '../../common/types/FeedRemoteDto';
import { PostSkeleton } from '../Posts/PostSkeleton';
import { Post } from '../../components/Posts/Post';
import { getFeed } from '../../util/ApiService';
import { PhotoOutlineIcon } from '../Icon/Icon';

export type FeedProps = StackProps;
export const Feed: React.FC<FeedProps & { filterLikes?: boolean }> = ({ filterLikes }) => {
    const initialFeedURL = filterLikes ? '/feed/likes' : '/feed';

    const { data, hasNextPage, fetchNextPage } = useSuspenseInfiniteQuery<FeedRemoteDto, AxiosError>({
        queryKey: ['getFeed', initialFeedURL],
        initialPageParam: initialFeedURL,
        queryFn: ({ pageParam }) => {
            return getFeed(pageParam as string);
        },
        getNextPageParam: (lastPage) => {
            return lastPage.links.next || undefined;
        },
        getPreviousPageParam: (previousPage) => {
            return previousPage.links.previous || undefined;
        },
    });

    //TODO: This error is not going to happen here because of suspense. Reimplement it properly
    // if (isLoading) return <FeedLoading {...stackProps} />;

    // if (error)
    //     return (
    //         <ErrorMessage
    //             name={t('Posts')}
    //             message={(error.response?.data as string | null) || t('Needs interned connection')}
    //             onRetry={refetch}
    //         />
    //     );

    if (data.pages.length === 0 || data.pages[0].items.length === 0) return <NoContent />;
    return <FeedLoaded feedData={data} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} />;
};

const NoContent: React.FC<StackProps> = ({ ...stackProps }) => {
    const { t } = useTranslation();

    return (
        <Center minH="80vh" {...stackProps}>
            <Stack spacing={5}>
                <Center>
                    <PhotoOutlineIcon width={150} height={150} />
                </Center>
                <Center>
                    <Text>{t('No posts here')}</Text>
                </Center>
            </Stack>
        </Center>
    );
};

const PLACEHOLDER_COUNT = 12;
export const FeedLoading: React.FC<StackProps> = (stackProps) => (
    <Stack {...stackProps}>
        {new Array(PLACEHOLDER_COUNT).fill(0).map((_, i) => (
            <PostSkeleton key={i} />
        ))}
    </Stack>
);

const FeedLoaded: React.FC<{
    feedData: InfiniteData<FeedRemoteDto>;
    hasNextPage: boolean | undefined;
    fetchNextPage: () => unknown;
}> = ({ feedData, hasNextPage, fetchNextPage }) => (
    <InfiniteScroll
        className="infiniteScroll"
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        loader={<PostSkeleton key={`${feedData.pages.length}_loader`} />}
    >
        {feedData.pages.map((page, pageId) =>
            page.items.map((post) => <Post key={`${pageId}_${post.id}`} postData={post} marginBottom={2} />),
        )}
    </InfiniteScroll>
);
