import React, { ReactNode, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { getApplicationData } from '../../util/AuthService';

export type AppData = {
    appId: string;
    appName: string;
    influencerName: string;
};

export let AppContext: React.Context<{
    appData: AppData | undefined;
    setAppData: React.Dispatch<React.SetStateAction<AppData | undefined>>;
}>;

export const AppContextWrapper = ({ children }: { children: ReactNode }) => {
    const [appData, setAppData] = useState<AppData>();

    AppContext = React.createContext({ appData, setAppData });
    const existingAppData = getApplicationData();

    if (!appData && existingAppData) {
        setAppData({
            appId: existingAppData.appId,
            appName: existingAppData.appName,
            influencerName: existingAppData.influencerName,
        });
    }

    return (
        <AppContext.Provider value={{ appData, setAppData }}>
            <HelmetProvider>
                {appData && (
                    <Helmet>
                        <title>{appData.appName}</title>
                    </Helmet>
                )}
                {children}
            </HelmetProvider>
        </AppContext.Provider>
    );
};
