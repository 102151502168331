import { Box, Center, Container, StackProps, Text } from '@chakra-ui/react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const SubscribeBox: React.FC<StackProps & { planId: string; planName: string; isStory?: boolean }> = ({
    planId,
    planName,
    isStory,
}) => {
    const { t } = useTranslation();
    const navigate: NavigateFunction = useNavigate();

    const triggerSubscription = () => {
        navigate(`/subscribe/${planId}`);
    };

    return (
        <Container
            variant={isStory ? 'storyOverlay' : 'overlay'}
            p={0}
            alignContent={'center'}
            justifyContent={'center'}
            onClick={triggerSubscription}
        >
            <Center>
                <Box>
                    <Center>
                        <Text variant="overlay">{t('This post is locked')}</Text>
                    </Center>
                    <Center>
                        <Text variant="overlay">{t(`Subscribe to plan '${planName}' to unlock`)}</Text>
                    </Center>
                </Box>
            </Center>
        </Container>
    );
};
