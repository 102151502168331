import axios from 'axios';
import { isInteger } from 'formik';
import { deviceType } from 'react-device-detect';

import { API_URL, UserType } from '../common/constants';
import { InfluencerLoginRemoteDto, SubscriberLoginRemoteDto } from '../common/types/LoginRemoteDto';
import { ApplicationRemoteDto } from '../common/types/ApplicationRemoteDto';
import { GetSignedUrlsRemoteDto } from '../common/types/GetSignedUrlsRemoteDto';
import {
    clearToken,
    clearUserData,
    initFromStore,
    saveApplicationData,
    saveToken as saveUserToken,
    getInfluencerPhoto,
    saveUserData,
    getApplicationData,
    getUserData,
} from './AuthService';
import { FeedRemoteDto } from '../common/types/FeedRemoteDto';
import { StoryRemoteDto } from '../common/types/StoryRemoteDto';
import { LikeUnlikeRemoteDto } from '../common/types/LikeUnlikeRemoteDto';
import { GetPlansRemoteDto, PlanRemoteDto } from '../common/types/PlanRemoteDto';
import { CreatePostResponseRemoteDto } from '../common/types/PostRemoteDto';
import { CommentDataRemoteDto, CommentRemoteDto } from '../common/types/CommentRemoteDto';
import { ChatHistoryRemoteDto, ChatUserDataRemoteDto, PaginatedChatListRemoteDto } from '../common/types/ChatRemoteDto';
import { PhylloTokenRemoteDto } from '../common/types/PhylloRemoteDto';
import { UpdateTextRemoteDto } from '../common/types/UpdateTextRemoteDto';
import { InfluencerStatsRemoteDto } from '../common/types/StatsRemoteDto';
import { StripeCheckoutTokenRemoteDto } from '../common/types/StripeCheckoutTokenRemoteDto';
import { EnrollRemoteDto, isEnrolledRemoteDto as IsEnrolledRemoteDto } from '../common/types/EnrollRemoteDto';

const client = axios.create();
client.defaults.headers.common['device-type'] = deviceType;
client.defaults.headers.common['app-domain'] = window.location.hostname.split('.')[0];
if (isInteger(client.defaults.headers.common['app-domain'])) {
    client.defaults.headers.common['app-domain'] = window.location.pathname.split('/').filter((element) => element)[0];
}

export const init = () => {
    initFromStore(client);
};

export const getHeaders = () => {
    init();
    return client.defaults.headers.common as {
        [header: string]: string;
    };
};

export const getApplication = async () => {
    const { data } = await client.get<ApplicationRemoteDto>(`${API_URL}/`);
    saveApplicationData(data);
    return data;
};

export const doSudoLogin = async (params: { email: string; password: string }) => {
    const { data } = await client.post<InfluencerLoginRemoteDto>(`${API_URL}/auth/influencer/login`, { ...params });
    saveUserData({ ...data.influencer }, false);
    saveUserToken(data.token, client);
    return data;
};

export const doLogin = async (params: { email: string; password: string }) => {
    const { data } = await client.post<SubscriberLoginRemoteDto>(`${API_URL}/auth/subscriber/login`, { ...params });
    saveUserData({ ...data.subscriber }, true);
    saveUserToken(data.token, client);
    return data;
};

export const doRegister = async (params: { name: string; email: string; password: string }) => {
    return (await client.post<SubscriberLoginRemoteDto>(`${API_URL}/auth/subscriber/register`, { ...params })).data;
};

export const doLogout = () => {
    clearToken(client);
    clearUserData();
};

export const getSignedUrls = async (params: { keys: string[] }) =>
    (
        await client.post<GetSignedUrlsRemoteDto>(`${API_URL}/download/convertKeysToSignedUrls`, {
            keys: params.keys,
        })
    ).data;

export const getFeed = async (nextPageURL: string) =>
    (await client.get<FeedRemoteDto>(`${API_URL}${nextPageURL}`)).data;

export const getStories = async () => (await client.get<StoryRemoteDto>(`${API_URL}/feed/stories`)).data;

export const doLike = async (params: { likeUrl: string; likeNotUnlike: boolean }) => {
    const fullUrl = `${API_URL}/${params.likeUrl}`;
    const { data } = await (params.likeNotUnlike
        ? client.put<LikeUnlikeRemoteDto>(fullUrl)
        : client.delete<LikeUnlikeRemoteDto>(fullUrl));
    return data;
};

export const getPlans = async () => (await client.get<GetPlansRemoteDto>(`${API_URL}/subscriber/plans`)).data;

export const createMultimediaPost = async (params: {
    caption: string;
    cost: number;
    planId: string;
    fileKeys: [string];
}) => {
    const { data } = await client.post<CreatePostResponseRemoteDto>(`${API_URL}/post/multimedia`, {
        ...params,
        ...{
            latitude: 0,
            longitude: 0,
        },
    });
    data.createdAt = new Date(data.createdAt);
    return data;
};

export const createBlogPost = async (params: { heading: string; cost: number; planId: string; content: string }) => {
    const { data } = await client.post<CreatePostResponseRemoteDto>(`${API_URL}/post/blog`, {
        ...params,
    });
    data.createdAt = new Date(data.createdAt);
    return data;
};

export const getComments = async (nextPageURL: string) =>
    (await client.get<CommentRemoteDto>(`${API_URL}${nextPageURL}`)).data;

export const addComment = async (params: { comment: string; postId: string }) => {
    const { data } = await client.post<CommentDataRemoteDto>(`${API_URL}/feed/${params.postId}/comment`, {
        text: params.comment,
    });
    data.createdAt = new Date(data.createdAt);
    return data;
};

export const deletePost = async (params: { postId: string }) => {
    await client.delete(`${API_URL}/post/${params.postId}`);
};

export const getChatUserData = async (params: { userId: string; userType: UserType }) => {
    const { data } = await client.get<ChatUserDataRemoteDto>(
        `${API_URL}/chat/user/${UserType[params.userType]}/${params.userId}`,
    );

    if (!data.profileUrl) {
        if (params.userType === UserType.INFLUENCER) data.profileUrl = await getInfluencerPhoto();
        else data.profileUrl = '//ssl.gstatic.com/accounts/ui/avatar_2x.png';
    }

    return data;
};

export const getChatHistory = async (nextPageURL: string) =>
    (await client.get<ChatHistoryRemoteDto>(`${API_URL}${nextPageURL}`)).data;

export const getChatList = async (nextPageURL: string) =>
    (await client.get<PaginatedChatListRemoteDto>(`${API_URL}${nextPageURL}`)).data;

export const getPhylloToken = async () =>
    (await client.get<PhylloTokenRemoteDto>(`${API_URL}/influencer/phylloToken`)).data;

export const updateApplicationName = async (params: { name: string }) => {
    const result = await client.put<UpdateTextRemoteDto>(`${API_URL}/influencer/application/name`, {
        newValue: params.name,
    });

    if (result.status === 200) {
        const appData = getApplicationData();
        saveApplicationData({
            ...appData,
            appName: params.name,
        });
    }

    return result.data;
};

export const updateInfluencerName = async (params: { name: string }) => {
    const result = await client.put<UpdateTextRemoteDto>(`${API_URL}/influencer/name`, {
        newValue: params.name,
    });

    if (result.status === 200) {
        const appData = getApplicationData();
        saveApplicationData({
            ...appData,
            influencerName: params.name,
        });

        const userData = getUserData();
        if (userData)
            saveUserData(
                {
                    id: userData.userId,
                    email: userData.userEmail,
                    name: params.name,
                },
                false,
            );
    }

    return result.data;
};

export const updateSubscriberName = async (params: { name: string }) => {
    const result = await client.put<UpdateTextRemoteDto>(`${API_URL}/subscriber/name`, {
        newValue: params.name,
    });

    if (result.status === 200) {
        const userData = getUserData();
        if (userData)
            saveUserData(
                {
                    id: userData.userId,
                    email: userData.userEmail,
                    name: params.name,
                },
                true,
            );
    }

    return result.data;
};

export const getInfluencerStats = async () =>
    (await client.get<InfluencerStatsRemoteDto>(`${API_URL}/reports/influencer/stats`)).data;

export const getStripeCheckoutToken = async (planId: string) => {
    return (
        await client.post<StripeCheckoutTokenRemoteDto>(`${API_URL}/subscriber/enroll/begin`, {
            planId,
        })
    ).data.clientSecret;
};

export const unEnroll = async () => (await client.delete<EnrollRemoteDto>(`${API_URL}/subscriber/enroll`)).data;

export const getEnrollment = async () => (await client.get<IsEnrolledRemoteDto>(`${API_URL}/subscriber/enroll`)).data;

export const deletePlan = async (params: { planId: string }) => {
    await client.delete(`${API_URL}/influencer/plan/${params.planId}`);
};

export const createPlan = async (params: { planName: string; planCost: number }) => {
    const { data } = await client.post<PlanRemoteDto>(`${API_URL}/influencer/plan/create`, {
        ...params,
    });
    data.createdAt = new Date(data.createdAt);
    return data;
};
