import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Avatar, Box, Button, Input, Link, Stack, StackProps, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { UserType } from '../../common/constants';
import { CommentDataRemoteDto, CommentRemoteDto } from '../../common/types/CommentRemoteDto';
import { LikeButton } from '../ToggleButton/LikeButton';
import { getInfluencerPhoto, getUserType } from '../../util/AuthService';
import { ProfileButton } from '../ProfileButton/ProfileButton';
import { addComment } from '../../util/ApiService';
import { focusStyles } from '../../theme/utils';
import { UserContext } from '../../router/UserContext';

export const Comment: React.FC<StackProps & { userId: string; userName: string; userType: UserType; text: string }> = ({
    userId,
    userName,
    userType,
    text,
    ...stackProps
}) => {
    const [isLoading, setLoading] = useState(false);
    const [isCommentLiked, setCommentLiked] = useState(false);
    const [userPhoto, setUserPhoto] = useState<string>();
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (user?.userType === UserType.INFLUENCER) {
            async function _() {
                setUserPhoto(await getInfluencerPhoto());
            }
            _();
        }
    }, [user]);

    const likeUnlikeAction = (newState: boolean) => {
        setLoading(true);
        setCommentLiked(newState);
    };

    return (
        <Stack direction="row" px={2} spacing={2} {...stackProps}>
            <Box py={1}>
                <Box borderColor="mode.text1" layerStyle="avatarBorder">
                    <Avatar bg="mode.text1" name={userName} size="sm" src={userPhoto} />
                </Box>
            </Box>
            <Stack flex={1} spacing={0}>
                <Link variant="profileSmall">{userName}</Link>
                <Text variant="small">{text}</Text>
            </Stack>
            {!(userId === user?.userId && userType === user?.userType) && (
                <LikeButton
                    isLoading={isLoading}
                    isLiked={isCommentLiked}
                    likeUnlikeAction={likeUnlikeAction}
                    iconProps={{ boxSize: 3 }}
                    size="xs"
                />
            )}
        </Stack>
    );
};

export const CommentSlim: React.FC<
    StackProps & { userId: string; userName: string; userType: UserType; text: string }
> = ({ userId, userName, userType, text, ...stackProps }) => {
    const [isLoading, setLoading] = useState(false);
    const [isCommentLiked, setCommentLiked] = useState(false);
    const { user } = useContext(UserContext);

    const likeUnlikeAction = (newState: boolean) => {
        setLoading(true);
        setCommentLiked(newState);
    };
    // console.log({ userId, savedId: getUserData().userId });

    return (
        <Stack direction="row" spacing={1} {...stackProps}>
            <Link variant="profile">{userName}</Link>
            <Text noOfLines={1} flex={1}>
                {text}
            </Text>
            {userId !== user?.userId ||
                (userType !== user?.userType && (
                    <LikeButton
                        isLoading={isLoading}
                        isLiked={isCommentLiked}
                        likeUnlikeAction={likeUnlikeAction}
                        iconProps={{ boxSize: 3 }}
                        size="xs"
                    />
                ))}
        </Stack>
    );
};

export const CommentSlimList: React.FC<{ postId: string; commentData: CommentRemoteDto }> = ({
    postId,
    commentData,
}) => {
    const { t } = useTranslation();
    if (!commentData.items.length) return null;

    return (
        <Stack pl={4} pr={4} spacing={1}>
            <Stack spacing={0}>
                {commentData.items
                    .slice(0, 2)
                    .reverse()
                    .map((comment, i) => (
                        <CommentSlim
                            key={i}
                            userId={comment.userId}
                            userName={comment.userName}
                            userType={getUserType(comment.userType)}
                            text={comment.text}
                        />
                    ))}
            </Stack>
            {commentData.items.length > 2 && (
                <Link _hover={{ textDecoration: 'none' }} color="mode.text2" href={`${postId}/comments`}>
                    {t('View all {{comments}} comments', { comments: commentData.items.length })}
                </Link>
            )}
        </Stack>
    );
};

export const CommentSkeleton: React.FC = () => (
    <Stack bg="mode.backgroundAlt" fontSize="sm" spacing={0} direction="row" layerStyle="loading" px={4} py={2}>
        <Box>
            <Box borderColor="mode.text1" layerStyle="avatarBorder">
                <Avatar bg="mode.text1" name=" " size="sm" />
            </Box>
        </Box>
        <Stack paddingStart={4} spacing={2} w="100%" py={1}>
            <Box layerStyle="skeleton" w="10%" />
            <Box layerStyle="skeleton" />
        </Stack>
    </Stack>
);

export const AddCommentBox: React.FC<
    StackProps & {
        isBlurred: boolean;
        postId: string;
        commentBoxRef: React.MutableRefObject<HTMLInputElement | null>;
        insertComment?: (comment: CommentDataRemoteDto) => void;
        setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
        isLoading?: boolean;
    }
> = ({ isBlurred, postId, commentBoxRef, insertComment, isLoading, setLoading, ...stackProps }) => {
    const [comment, setComment] = useState('');

    const { t } = useTranslation();
    const label = t('Add a comment…');
    const queryClient = useQueryClient();

    const addCommentMutation = useMutation<CommentDataRemoteDto, AxiosError, { comment: string; postId: string }>({
        mutationFn: addComment,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['getFeed'] });
            if (insertComment) insertComment(data);
            setComment('');

            if (setLoading) setLoading(false);
        },
        onError: () => {
            if (setLoading) setLoading(false);
        },
    });

    const commentPostClick = async () => {
        if (setLoading) setLoading(true);
        addCommentMutation.mutate({ comment, postId });
    };

    if (!isBlurred)
        return (
            <Stack align="center" borderColor="mode.border" direction="row" p={2} spacing={0} {...stackProps}>
                <ProfileButton />
                <Input
                    ref={commentBoxRef}
                    aria-label={label}
                    bg="none"
                    placeholder={label}
                    size="sm"
                    value={comment}
                    variant="ghost"
                    onChange={({ target }: ChangeEvent<HTMLInputElement>) => setComment(target.value)}
                    disabled={isLoading}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            commentPostClick();
                        }
                    }}
                />
                <Button
                    {...focusStyles}
                    color="mode.text4"
                    fontWeight="semibold"
                    isDisabled={!comment}
                    px={2}
                    size="sm"
                    variant="ghost"
                    disabled={isLoading}
                    onClick={commentPostClick}
                >
                    {t('Post')}
                </Button>
            </Stack>
        );
    return null;
};
