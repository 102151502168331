import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Stack } from '@chakra-ui/react';

import { ImagePostFileData, PostMinimalRemoteDto, VideoPostFileData } from '../../common/types/PostRemoteDto';

export const StoryBarProfile: React.FC<PostMinimalRemoteDto> = ({ id, hasSeen, item }: PostMinimalRemoteDto) => {
    const navigate = useNavigate();

    const onStoryClick = () => {
        navigate(`/story/${id}`);
    };

    return (
        <Stack align="center" cursor="pointer" justify="center">
            <Box layerStyle={hasSeen ? 'storiesSeen' : 'avatarBorder'} onClick={onStoryClick}>
                <Avatar
                    bg="mode.secondary"
                    size="lg"
                    src={
                        (item.content[0] as ImagePostFileData).contentData.URL ||
                        (item.content[0] as VideoPostFileData).contentData.thumbnailURL
                    }
                />
            </Box>
        </Stack>
    );
};

export const StoryBarProfileSkeleton: React.FC = () => (
    <Stack align="center" cursor="pointer" justify="center" spacing={3}>
        <Box borderColor="mode.text1" layerStyle="avatarBorder">
            <Avatar bg="mode.text1" name=" " size="lg" />
        </Box>
        <Box layerStyle="skeleton" w={16} />
    </Stack>
);
