import { Axios } from 'axios';
import { Optional } from 'utility-types';

import { UserType } from '../common/constants';
import { ApplicationRemoteDto } from '../common/types/ApplicationRemoteDto';
import { InfluencerRemoteDto, SubscriberRemoteDto } from '../common/types/LoginRemoteDto';

export const saveApplicationData = (data: ApplicationRemoteDto) => {
    localStorage.setItem('appId', data.appId);
    localStorage.setItem('appName', data.appName);
    localStorage.setItem('influencerName', data.influencerName);

    if (data.appPhotoUrl) localStorage.setItem('appPhoto', data.appPhotoUrl);
    if (data.influencerPhotoUrl) localStorage.setItem('influencerPhoto', data.influencerPhotoUrl);
};

export const getApplicationData = () => {
    return {
        appId: localStorage.getItem('appId') as string,
        appName: localStorage.getItem('appName') as string,
        influencerName: localStorage.getItem('influencerName') as string,
    };
};

export const getApplicationPhoto = async () => {
    const appPhoto = localStorage.getItem('appPhoto');

    if (!appPhoto) return '//ssl.gstatic.com/accounts/ui/avatar_2x.png';
    else return appPhoto;
};

export const getInfluencerPhoto = async () => {
    const influencerPhoto = localStorage.getItem('influencerPhoto');

    if (!influencerPhoto) return '//ssl.gstatic.com/accounts/ui/avatar_2x.png';
    else return influencerPhoto;
};

export const getUserPhoto = async () => {
    const userPhoto = localStorage.getItem('userPhoto');

    if (!userPhoto) return '//ssl.gstatic.com/accounts/ui/avatar_2x.png';
    else return userPhoto;
};

export const getToken = () => {
    return localStorage.getItem('APIToken');
};

export const saveToken = async (token: string, client: Axios) => {
    localStorage.setItem('APIToken', token);
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const clearToken = (client: Axios) => {
    localStorage.removeItem('APIToken');
    delete client.defaults.headers.common.Authorization;
};

export const isAuthenticated = () => {
    if (localStorage.getItem('APIToken')) return true;
    else return false;
};

export function saveUserData(
    user: Optional<Pick<SubscriberRemoteDto, 'id' | 'name' | 'email' | 'userPhoto'>, 'userPhoto'>,
    isSubscriber: true,
): void;
export function saveUserData(
    user: Optional<Pick<InfluencerRemoteDto, 'id' | 'name' | 'email' | 'userPhoto'>, 'userPhoto'>,
    isSubscriber: false,
): void;
export function saveUserData(
    user:
        | Optional<Pick<SubscriberRemoteDto, 'id' | 'name' | 'email' | 'userPhoto'>, 'userPhoto'>
        | Optional<Pick<InfluencerRemoteDto, 'id' | 'name' | 'email' | 'userPhoto'>, 'userPhoto'>,
    isSubscriber: boolean,
) {
    localStorage.setItem('userId', user.id);
    localStorage.setItem('userName', user.name);
    localStorage.setItem('userEmail', user.email);
    localStorage.setItem('userType', UserType[isSubscriber ? UserType.SUBSCRIBER : UserType.INFLUENCER]);
    if (user.userPhoto) localStorage.setItem('userPhoto', user.userPhoto);
}

export type UserData = { userId: string; userName: string; userEmail: string; userType: UserType };
export const getUserData = () => {
    const data: UserData = {
        userId: localStorage.getItem('userId') as string,
        userName: localStorage.getItem('userName') as string,
        userEmail: localStorage.getItem('userEmail') as string,
        userType:
            (localStorage.getItem('userType') as string) === 'SUBSCRIBER' ? UserType.SUBSCRIBER : UserType.INFLUENCER,
    };

    if (!data.userEmail || !data.userId || !data.userName || data.userType === undefined) return null;
    return data;
};

export const clearUserData = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userType');
    localStorage.removeItem('userPhoto');
};

export const initFromStore = (client: Axios) => {
    if (isAuthenticated()) {
        client.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
    }
};

export const getUserType = (userType: string) => {
    return userType === 'INFLUENCER' ? UserType.INFLUENCER : UserType.SUBSCRIBER;
};
