import React, { useContext, useRef, useState } from 'react';
import { Box, Link, Stack, StackProps } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getDaysAgo } from '../../util/DateService';
import { PostFooter as Footer } from './PostFooter';
import { ActionButtons, Caption, Header, Likes } from './PostComponent';
import { LikeUnlikeRemoteDto } from '../../common/types/LikeUnlikeRemoteDto';
import { CommentDataRemoteDto } from '../../common/types/CommentRemoteDto';
import { CommentSlimList } from '../../components/Comments/CommentComponent';
import { PostRemoteDto } from '../../common/types/PostRemoteDto';
import { MultiMediaPost } from './MultiMediaPost';
import { UserType } from '../../common/constants';
import { doLike } from '../../util/ApiService';
import { SubscribeBox } from '../Subscription/SubscribeBox';
import { UserContext } from '../../router/UserContext';
import { BlogPost } from './BlogPost';

export const Post: React.FC<StackProps & { postData: PostRemoteDto }> = ({ postData, ...stackProps }) => {
    const [post, setPost] = useState<PostRemoteDto>(postData);
    const commentBoxRef = useRef<HTMLInputElement>(null);
    const [isLoading, setLoading] = useState(false);
    const { user } = useContext(UserContext);

    const queryClient = useQueryClient();
    const mutation = useMutation<LikeUnlikeRemoteDto, AxiosError, { likeUrl: string; likeNotUnlike: boolean }>({
        mutationFn: doLike,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['getFeed'] });
            likeUnlikeUi(data.likeStatus === 'LIKED');
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        },
    });

    const likeUnlikeUi = (newState: boolean) => {
        if (post.doILikePost !== newState) {
            const newPostState = Object.assign({}, post);
            newPostState.doILikePost = newState;

            if (newState) newPostState.likesCount += 1;
            else newPostState.likesCount -= 1;

            setPost(newPostState);
        }
    };

    const likeUnlikeAction = (newState: boolean) => {
        if (post.doILikePost !== newState) {
            setLoading(true);
            mutation.mutate({ likeUrl: `feed/${postData.id}/like`, likeNotUnlike: newState });
        }
    };

    const likeAction = () => {
        if (post.isBlurred || user?.userType === UserType.INFLUENCER) return;
        likeUnlikeAction(true);
    };

    const commentClick = () => {
        commentBoxRef.current?.focus();
    };

    const insertComment = (comment: CommentDataRemoteDto) => {
        const newPostState = Object.assign({}, post);
        newPostState.comments.items.unshift(comment);
        newPostState.comments.meta.totalItems++;
        newPostState.comments.meta.itemCount++;
        setPost(newPostState);
    };

    return (
        <Stack
            {...stackProps}
            bg="mode.backgroundAlt"
            borderColor="mode.border"
            borderTopWidth={1}
            flex={1}
            fontSize="sm"
            spacing={0}
        >
            <Header {...post} />

            <Box position="relative">
                {post.item.type === 'MultimediaPost' && (
                    <MultiMediaPost caption={post.item.caption} content={post.item.content} doubleTap={likeAction} />
                )}
                {post.item.type === 'BlogPost' && (
                    <BlogPost heading={post.item.heading} content={post.item.content} doubleTap={likeAction} />
                )}

                {post.isBlurred && post.bestPlan && (
                    <SubscribeBox planId={post.bestPlan.id} planName={post.bestPlan.planName} />
                )}
            </Box>

            <ActionButtons
                doILikePost={post.doILikePost}
                // postId={post.id}
                likeUnlikeAction={likeUnlikeAction}
                commentClickAction={commentClick}
                isBlurred={post.isBlurred}
                isLoading={isLoading}
            />
            <Likes likesCount={post.likesCount} />

            {post.item.type === 'MultimediaPost' && <Caption px={4} py={1} text={post.item.caption} />}

            <Link px={4} color="mode.text2" fontSize=".65rem">
                {getDaysAgo(post.createdAt)}
            </Link>

            <CommentSlimList postId={post.id} commentData={post.comments} />

            <Footer
                postId={post.id}
                isBlurred={post.isBlurred}
                setLoading={setLoading}
                isLoading={isLoading}
                commentBoxRef={commentBoxRef}
                insertComment={insertComment}
            />
        </Stack>
    );
};
